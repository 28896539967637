$(function(){
  //init
  createModalGallery();

  ////////////////////////////////////////////////////////////////////////////////////////////////

  //モーダル内でスライダーを作成
  let swiperModal;
  const swiperInModal = function() {
    swiperModal = new Swiper ('.js-modalSwiper', {
      loop: true,
      autoplay: false,
      speed: 1000,
      effect: 'fade',

      //ページネーション
      pagination: {
        el: ".swiper-pagination",
        type: "fraction",
      },

      // ナビボタン
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
    });
  }


  //モーダル作成
  function createModalGallery() {
    const trigger = $('.js-modalInline');

    trigger.magnificPopup({
      type: 'inline',
      preloader: false,
      closeBtnInside: false,
      closeOnBgClick: true,
      showCloseBtn: false,
      callbacks: {
        open: function() {
          swiperInModal();
        },
        // close: function() {}
      },
    });
  }


  //閉じるリンクの設定
  $(document).on('click', '.js-modalCloseBtn', function (e) {
    e.preventDefault();
    $.magnificPopup.close();
  });

});